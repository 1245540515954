.order-heading {
  text-align: center;
  /* padding: 2rem; */
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;

  letter-spacing: -0.02em;
  color: #1a1a1a;
}

.clock-icon {
  width: 24px;
  height: 24px;
  left: 0px;
  font-size: 23px;
  color: black;
}

.outer-order-type {
  display: flex;
  justify-content: center;
}

.order-type-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  color: #1a1a1a;
  padding: 14px 16px;
  cursor: pointer;
}

.order-type-text:hover {
  border-bottom: 3px solid #2d5c48;
}

.act {
  border-bottom: 3px solid #2d5c48;
}

.main-order-details {
    background-color: #fff;
    box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
    border-radius: 4px;
    padding: 2rem;
    margin-top: 40px;
    margin-bottom: 40px;
}

.order-number {
  display: flex;
  justify-content: space-between;
}
.track-order-btn {
  position: relative;
  top: -43px;
  left: 230px;
  width: 25%;
  border-radius: 5px;
  /* margin-bottom: 1rem; */
  background: #364d49 !important;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  /* display: flex; */
  align-items: center;
  color: white;
  font-family: "Playfair Display";
  text-transform: capitalize;
  cursor: pointer;
}
.order-text {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  margin-left: 15px;
  font-family: "Playfair Display";
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
}

.order-number-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #666666;
}

.outer-delivery-image {
  display: flex;
  justify-content: center;
}

.delivery-image-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-top: 1rem;
  color: #666666;
}

.delivery-time {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1a1a1a;
}

.custom-width {
  width: 50%;
  margin: auto;
  margin-bottom: 22px;
  border-radius: 14px;
}

.sub-details {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #666666;
}

.sub-total {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
}

.current-order-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #666666;
}

.order-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  gap: 24px;
  width: auto;
  height: 122px;
  background: #ffffff;
  /* border: 2px solid #FED301; */
  border-radius: 4px;
  box-shadow: 1px 1px 3px 1px rgba(222,222,222,0.75);
  padding-bottom: 23px;
  padding-top: 75px;
}

.order-card:hover {
  /* padding-bottom: 23px; */
  cursor: pointer;
  /* display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 9px;
gap: 24px;
width: auto;
height: 190px;
background: #FFFFFF; */
  border: 2px solid #2d5c48;
  /* border-radius: 16px; */
}

.order-card-left-side {
  padding: 0px;
  gap: 20px;

  width: 131px;
  height: 110px;
}

.order-card-price {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  padding-bottom: 1px;
}

.order-card-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  /* line-height: 20px; */
  color: #999999;
}

.order-card-total-price {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #666666;
}

.card-ovel {
  width: 55px;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #d60000;
}

.past-orders {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #666666;
}

.reorder {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #d60000;
}
[data-toggle="collapse"].acordeon-toggle:after {
  content: "/`\\";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  transition: all 0.5s;
}

[data-toggle="collapse"].collapsed.acordeon-toggle:after {
  color: #1a1a1a;
  content: " \\./";
  padding-bottom: 4px;
  padding-top: 4px;
  border-radius: 50%;
}
.scrollModify {
  max-height: calc(calc(80vh - (24px * 2) - (73px + 73px)) - 50px);

  overflow-y: auto;
}

.order-detail-height{
  max-height: 300px;
  overflow-y: auto;
}

.order-detail-height::-webkit-scrollbar {
  width: 8px; 
}

.order-detail-height::-webkit-scrollbar-thumb {
  background-color: #E64427; 
  border-radius: 6px; 
}

.order-detail-height::-webkit-scrollbar-track {
  background-color: #ffffff; 
}