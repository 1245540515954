@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Mooli&family=Noto+Sans:wght@400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@500&family=REM:wght@200;300&display=swap');

.cart {
  position: absolute;
  width: 27px;
  height: 41px;
  left: 1268px;
  top: 27px;
}

.cart-summary {
  border-radius: 5px !important;
}

.myCart-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  color: #1a1a1a;
  position: relative;
  bottom: 22px;
  font-family: 'Quicksand', sans-serif;
}

.cart-product-name {
  color: var(--Primary-color, #000000);
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  position: relative;
  left: 5px;
  height: 25px;
  width: 180px;
  white-space: nowrap;
}

.ct-items-div {
  font-family: 'Quicksand', sans-serif;
  overflow: auto;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 13px;
  padding-right: 13px;
  height: calc(100vh - 190px);
}

.cart-product-price {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-right: 47px;
  color: #4d4d4d;
  font-family: 'Quicksand', sans-serif;
}

.cart-icons {
  display: flex;
  width: 88px;
  height: 31px;
  align-items: flex-start;
  position: relative;
  top: 10px;
  left: 5px;
}

.cart-product-main {
  display: flex;
  justify-content: space-between;
}

.cart-outer-quantity {
  position: relative;
  left: -10px;
  /* top: -1px; */
  padding: 2px;
  grid-gap: 16px;
  grid-gap: 16px;
  /* gap: 16px; */
  width: 111px !important;
  height: 31px;
  background: #ffffff;
  /* border: 1px solid #cccccc; */
  border-radius: 8px;
}

.cart-checkout {
  color: #FFF;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: initial;
}

.cart-cont {
  color: var(--Neutral-color, #212524);
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 30px;
  padding: 6px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.cart-item {
  margin-top: -13px;
}

.cart-extra {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin: auto;
}

.cart-extra:hover {
  color: #666666;
}

.sidebar-menu {
  position: fixed;
  right: -4px;
  top: -5px;
  z-index: 1100;
  width: 503px;
  height: 100%;
  overflow: auto;
  transition: all 0.5s ease 0s;
  transform: translateX(0);
  -webkit-transition: 0.5s ease all;
  background: #FFF;
  padding: 0rem;
  scrollbar-width: none;
  /* Hide the scrollbar */
  overflow-y: scroll;
  /* Keep the scrolling behavior */
  scrollbar-color: transparent transparent;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE/Edge */
}

.sidebar-menu::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar for Chrome, Safari, and Opera */
}

.sidebar-menu::-ms-overflow-style {
  display: none;
  /* Hide the scrollbar for Edge and IE */
}


.cart-upper-heading {
  display: flex;
  height: 100px;
  padding: 52px 24px 16px 0px;
  /* padding: 52px 24px 16px 27px; */
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  flex-shrink: 0;
  width: 100%;
  border-bottom: 1px solid var(--neutral-neutral-100, #C0C1C3);
  background: #FFF;
}

.cart-heading {
  color: var(--Primary-color, #171818);
  font-family: 'Quicksand', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cart-cancel-btn {
  right: 20px !important;
  color: #413434;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

}

.cart-order-list {
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* max-height: 350px; */
  /* overflow: auto; */
}

.cart-coupon {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #2d5c48;
  margin: auto;
  font-family: 'Quicksand', sans-serif;
  display: flex;
  align-items: center;
}

.cart-coupon-apply {
  width: auto !important;
  margin-left: -5px;
}

.form-inner {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  transition: all 0.3s;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background-color: #fff;
  color: #666666;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  margin: auto;
}

.form-inner:focus {
  border: 1px solid red !important;
}

.cart-apply-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  grid-gap: 4px;
  gap: 4px;
  width: 61px;
  height: 32px;
  background: #364d49;
  border-radius: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 12.8px;
  line-height: 20px;
  color: white;
  border: none;
  position: relative;
  top: -40px;
  left: 292px;
  font-family: 'Quicksand', sans-serif;
}

.cart-subtotal {
  display: flex;
  justify-content: space-between;
  /* margin: 0.5rem; */
}

.cart-single-item-left {
  color: var(--Neutral-color, #212524);
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px !important;

}

.cart-single-item-right {
  color: var(--Neutral-color, #212524);
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cart-quantity-total {
  background-color: #818f71;
  width: 30px;
  border-radius: 100px;
  padding-top: 6px;
  color: #fff !important;
  height: 30px;
  margin-top: 3px !important;
  margin-left: 5px !important;
}

.cart-shopping {
  color: black !important;
}

.cart-checkout {
  color: white !important;
}

.product-modal-special {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin: 0px;
  margin-left: 1rem;
}

.product-modal-more-optional {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #666666;
}

.special-ins {
  font-style: normal;
  font-weight: 400;
  max-height: 100px !important;
  font-size: 15px;
  border-radius: 5px;
  line-height: 10px;
  color: #666666;
  width: 455px;
  height: 118px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--grey-grey-100, #BABBBB);
  background: #FFF;
}

.cart-buttons {
  display: flex;
  width: 458px;
  padding: 16px 125px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 14px;
  background: var(--Primary-color, #E64427) !important;
  height: 54px;
  color: #FFF;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cart-buttons:hover {
  background-color: #E64427;
}

.final-price {
  color: rgb(255, 255, 255) !important;
  margin-top: 10px !important;
  margin-right: 7px !important;
  font-size: 14px !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .cart-apply-btn {
    top: -38px;
    left: 251px;
  }
}

.cart-shopping {
  padding-top: 4px;

  height: 32px;
  margin-top: 5px;
  font-size: 16px;
  /* margin-bottom: -15px; */
  width: 71px !important;
  margin-left: -45px !important;
}

.cart-shopping svg {
  font-size: 20px;
  overflow: hidden;
  vertical-align: unset !important;
  color: white;
}

#mbl-cart-model-close-btn {
  position: absolute;
  top: 10px;
  right: 10px !important;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

@media (min-width: 320px) and (max-width: 480px) {
  .sidebar-menu {
    position: fixed;
    top: -5px;
    z-index: 1100;
    width: -webkit-fill-available;
    height: 100%;
    overflow: auto;
    transition: all 0.5s ease 0s;
    transform: translateX(0);
    -webkit-transition: 0.5s ease all;
    background: #f7f7f7;
    padding: 1.5rem;
  }

  .open {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  /* .open {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  } */
}

@media (min-width: 768px) and (max-width: 1024px) {}

/* @media (min-width: 1024px) and (max-width: 1280px) {
  .open {
    -webkit-transform: translateX(0px) !important;
    transform: translateX(0px) !important;
  }
} */

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1.5rem;
}


.cart-image {
  display: flex;
  width: 64px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.cart-choice-qty {
  color: var(--Primary-color, #364D49);
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 89px;
  top: 19px;
  white-space: nowrap;
}

.product-price {
  color: var(--Primary-color, #000000);
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: end;
}

.left-icon {
  display: flex;
  width: 29px;
  height: 31px;
  padding: 9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.right-icon {
  margin-top: 0px;
  cursor: pointer;
  display: flex;
  width: 29px;
  height: 31px;
  padding: 9px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.od {
  color: var(--Primary-color, #060606);
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: -5px;
}

.spi {
  color: var(--Neutral-color, #0d0e0e);
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 8px;
}

.optional {
  color: var(--Neutral-color, #212524);
  font-family: 'Quicksand', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  right: 53%;
  top: 3px;
}

#asap {
  display: flex;
  width: 455px;
  height: 44px;
  padding: 1px 8px 1px 18px;
  justify-content: flex-end;
  align-items: center;
  gap: 257px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--grey-grey-100, #BABBBB);
  background: #FFF;
  padding-right: 20px !important;

}

.cusomt_Input {
  width: 455px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(73, 72, 72);
  border: 1px solid var(--grey-grey-100, #BABBBB);
  padding: 0px 18px;
  position: relative;
  border-radius: 6px;

}



.custom_dropDown:nth-child(1) {
  position: absolute;
  background: white;
  width: 459px;
  z-index: 999999;
  height: 200px;
  overflow-y: auto;
  padding: 10px 16px;
  border-radius: 6px;
  left: 1%;
  font-family: 'Quicksand', sans-serif;
  border: 1px solid #BABBBB;

}

.width_increase {
  width: 455px !important;
}

.custom_dropDown:nth-child(2) {
  position: absolute;
  background: white;
  width: 455px;
  z-index: 999999;
  height: 200px;
  overflow-y: auto;
  padding: 10px 2px;
  border-radius: 6px;
  left: 1%;
  font-family: 'Quicksand', sans-serif;
  border: 1px solid #BABBBB;

}

.custom_dropDown::-webkit-scrollbar {
  width: 8px;
}

.custom_dropDown::-webkit-scrollbar-thumb {
  background-color: #949494;
  border-radius: 6px;
}

.custom_dropDown::-webkit-scrollbar-track {
  background-color: #ffffff;
}


.margin_custom_dropdown {
  left: 2.3% !important;
}

.cusomt_Input span {
  font-family: 'Quicksand', sans-serif;
}

.custom_dropDown div {
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
}


.cart-single-item-left {}

.divider {
  height: 1px;
  background: var(--grey-grey-100, #626363);
}

.chk-out-btn {
  bottom: 0px;
  display: flex;
  padding: 18px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background: #FFF;
  box-shadow: 0px -6px 18px 0px rgba(147, 142, 142, 0.25);
  width: auto;
  position: relative;
  top: 0px;
}


.basket-icon {
  margin-top: 170px;
  position: relative;
  left: 0px;
  height: 133px;
}

.empty-cart {
  color: #2F2F2F;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 90px;
}

.empty-cart-content {
  color: #000;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.57;
  margin-top: 20px;
}

.empty-ct-items-div {
  font-family: 'Playfair Display';
  overflow: auto;
  padding: 13px;
  height: calc(100vh - 190px);
}

.font_custom_cart {
  font-family: 'Quicksand', sans-serif;
}

.disabled-btn-checkout {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.7;
}

.stop_temp{
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.blockstore{
  pointer-events: none;
  cursor: none;
}

@media only screen and (min-width: 1500px) {
  .table_number {
    width: 455px !important;
  }
}

.font_icon_close {
  position: absolute;
  right: 12%;
  top: 6%;
}

.closeIconMobile{
  display: none;
}

@media only screen and (max-width:600px) {

  .closeIconMobile{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    position: absolute;
    right: 20px;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    z-index: 100;
  }

  .modal_container{
    height: 90vh;
    width: 100vw !important;
  }
  .table_number {
    width: 100% !important;
  }

  /* .cusomt_Input{
     width: 100% !important;
  } */

  #asap {
    width: 100% !important;
  }

  .special-ins {
    width: 100%;
  }

  .cart-buttons {
    width: 100%;
  }

  .customModal-mobile{
    width: 100% !important;
    height: 92vh !important;
    margin: 0px !important;
    padding: 10px 0px !important;
    overflow-y: hidden;
  }


  .p-3-1 {
   margin-top: 60px;
  }

  .cart-icons {
    left: -22px;
  }

  .ct-items-div{
    height: 70vh; 
    margin-top: 20%;
    overflow-y: auto;
   }

   .empty-ct-items-div{
    height: 80vh;
  }

  .chk-out-btn{
    padding: 18px 22px !important;
    width: 100%;
    position: fixed;
   top: 90%;
   height: 80px;
  }

  #mbl-cart-model-close-btn {
    display: none !important;
  }

  .header_mbl_Cart{
    align-items: center;
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 0%;
    width: 100%;
    padding-bottom: 10px;
    height: 69px;
    z-index: 7800;
    box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.25);
  }

  .product-price {
    font-size: 18px;
  }

  .od {
    font-size: 18px;
  }

  .cart-single-item-left {
    font-size: 14px;
  }

  .spi {
    font-size: 14px
  }

  .cart-single-item-right {
    font-size: 16px;
  }

  .chk-out-btn {
    font-size: 16px;
  }

  .cart-choice-qty {
    left: 89px;
  }

  .width_increase {
    width: 100% !important;
  }
  .custom_dropDown:nth-child(2){
    width: 100%;
  }
}

@media only screen and (min-width:388px) and (max-width:600px) {
  .optional {
    right: 38%;
  }

  .custom_dropDown {
    width: 320px;
  }


}

@media only screen and (min-width:410px) and (max-width:600px) {
  .cusomt_Input {
    width: 370px;
  }

  .custom_dropDown {
    width: 350px;
  }

 
}

@media only screen and (min-width:388px) and (max-width:405px) {
  .cusomt_Input {
    width: 345px;
  }
}

@media only screen and (min-width:320px) and (max-width:380px) {

  .cusomt_Input {
    width: 330px;
  }

  .optional {
    right: 34%;
  }

  .custom_dropDown {
    width: 303px;
  }
}


.border-custom {
  border: 1px solid #FFB1A3;

background: #FFF;

box-shadow: 0px 3px 9px 0px rgba(230, 68, 39, 0.13);
}

.classHover {
  padding: 4px 16px;
}

.classHover:hover {
  background-color: rgb(237, 234, 234);
}

@media only screen and (min-width:766px) and (max-width:991px){
  .header-icon:not(:last-child){
    margin-right: 4.2rem !important;
  }

  .cart_quanity-header{
    right: -84% ;
  }
}