.language-container{
    height: 100vh;
    /* margin-top: 30%; */
}

.lang-title1{
color: #201E1E;
margin-bottom: 40px;
font-family: 'QuickSand';
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.btn-inactive{
   display: flex;
width: 358px;
padding: 14px 137px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: var(--primary-primary-50, #EBEDED);

color: #E64427;

text-align: center;
font-family: 'QuickSand';
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
border: none;
}

.btn-active{
    border-radius: 8px;
background: #E64427 !important;
color: #FFF !important;
}

.btn_wrapper{
    gap: 20px;
}

.update-btn{
    display: flex;
width: 358px;
padding: 14px 152px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: #E64427;
color: #FFF;

text-align: center;
font-family: 'QuickSand';
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}