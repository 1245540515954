@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,800;0,900;1,700&display=swap');


@font-face {
  font-family: "GillSansLight";
  src: local("GillSansLight"),
    url('./fonts/Gill\ Sans\ Bold.otf') format('woff'),
    url('./fonts/Gill\ Sans\ Light.otf') format('woff2');
}

@font-face {
  font-family: "GillSansHeavy";
  src: local("GillSansHeavy"),
    url('./fonts/Gill\ Sans\ Heavy.otf') format('woff'),
    url('./fonts/Gill\ Sans\ Medium.otf') format('woff2'),
    url('./fonts/GillSans\ Condensed.otf') format('woff2');
}

@font-face {
  font-family: "GillSansMedium";
  src: local("GillSansMedium"),
    url('./fonts/Gill\ Sans\ Medium.otf') format('woff'),
     url('./fonts/Gill\ Sans.otf') format('woff'),
}

body{
  height: 100vh;
  overflow-x: hidden;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



