
.main_footer{
    background-color: black;
    width: 100%;
    height: 256px;
    position: relative;
  flex: 1;
  margin-top: auto;
  
}

.footer_desc{
    color: white;
    font-family: 'Quicksand', sans-serif;
    width: 100%;
    text-align: center;
}

.footer_link{
    color: white;
    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    white-space: nowrap;
    margin: 0px 8px;
    
}

.footer_link:hover{
    color: #E64427;
}

.custom_text{
    text-align: start;
    color: white;
        font-family: 'Quicksand', sans-serif;
}


@media only screen and (max-width:600px) {

    .main_footer{
        height: 313px; 
        z-index: 999;
        padding-bottom: 10px;
    }
    
    
}