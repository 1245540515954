
.modalSettingBanner {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 32%;
  display: flex;
  background-color: white;
  box-shadow: rgba(60, 66, 87, 0.03) 0px 7px 13px 0px, rgba(0, 0, 0, 0.08) 0px 3px 6px 0px;
  flex-direction: column;
  border-radius: 4pt;
  height: 30vh;
  position: relative;
  padding: 0px !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;

}

.react-responsive-modal-root{
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: fixed;
  z-index: 1051;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.react-responsive-modal-container{
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: fixed;
  z-index: 1051;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.5);
  overflow: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.modal-body {
  padding: 2.5px !important;
  max-height: 91vh;
  /* overflow: auto; */
}
.modal-content {
  border-radius: 10px;
}
.cross-svg {
  width: 65%;
  position: relative;
  bottom: 26px;
}
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
.mdaalSetting .react-responsive-modal-closeButton {
  color: #fff;
  position: absolute;
    top: 14px;
    right: 45px !important;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
}
.product-modal-detail{
  padding-left: 30px;
}
.product-modal-name {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  margin-left: 3rem;
  color: #2d5c48;
  margin-top: 12px !important;
  position: relative;
  left: -35px;
  font-family: "Playfair Display";
}
.product-modal-price {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2d5c48;
  margin-left: 3rem;
}

.product-modal-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgb(13, 37, 49);
  margin-left: 3rem;
  margin-right: 3rem;
  position: relative;
  left: -25px;
  font-family: "Playfair Display";
}
.modal-single-item {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  /* line-height: 2px; */
  display: flex;
  align-items: center;
  /* color: #666666; */
  margin-left: 1.5rem;
  font-family: "Playfair Display";
}
.allerges-list
{
  display: flex;
  position: relative;
  left: -35px;
}

.product-modal-more {
  /* background-color: #f7f7f7; */
  /* border-radius: 24px; */
  /* margin-left: 20px; */
  position: relative;
  /* left: -35px; */
  padding-left: 10px;
}
.product-modal-more-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin-bottom: 0px;
}

.product-modal-body {
  /* margin-top: 20px; */
  padding: 0 30px 10px;
}
.product-modal-modal-index {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #666666;
  margin-left: 1.5rem;
}
.product-modal-special {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin: 0px;
  margin-left: 1rem;
}

.form-text {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 16px;
}

.cart-product-quantity {
  /* background-color: #2d5c48; */
  /* height: 20px; */
  width: 20px;
  /* border-radius: 100px; */
  /* padding-top: 2.5px; */
  /* padding-left: 6px; */
  color: #2d5c48 !important;
  position: relative;
  left: 18px;

  /* margin-top: 2px; */
}
.product-ingrediant {
  margin-left: 27px;
}
/* .cart-outer-quantity{

    padding: 8px 16px;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    width: 111px;
    height: 41px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 100px;
 
} */

.cart-product-icons {
  display: flex;
  /* background-color: #fff; */
  /* width: 100%; */
  justify-content: space-between;
}
.multi-mod-quantity{
  padding: 2px 16px;
  grid-gap: 16px;
  width: 103px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 100px;
  display: inline-block;
  position: relative;
}
.cart-outer-quantitys {
  display: flex;
    flex-direction: row;
    padding: 16px;
    border-style: none;
    height: fit-content;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 6px;
    width: 120px;
    background: rgb(243, 244, 239);
    position: relative;
    top: 5px;
  /* padding: 6px 16px;
  grid-gap: 16px;
  width: 90%;
  height: 39px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  left: 25px; */
}
.crt-btn {
  display: flex;
  position: sticky;
  bottom: 0px;
  background-color: #f7f7f7;
}
.react-responsive-modal-overlay
{
  position: absolute;
}
.modal-cart-btn {
  display: flex;
    flex-direction: row;
    padding: 16px 24px;
    border-style: none;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 6px;
    width: 100%;
    background: #2f4e49;
    cursor: pointer;
  /* display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;
  border: none;
cursor: pointer;
  height: 40px;
  background: #2d5c48;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 25px;
  color: #1a1a1a; */
}

.product-ingrediant-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.modal-cart-outer-div {
  margin-top: 5px;
  width: 90%;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: black;
}
input[type="checkbox"] {
  /* margin: 0px;
  display: flex;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(139, 143, 161);
  box-sizing: border-box;
  border-radius: 4px;

  color: white;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 24px;
  margin-left: -20px;
  outline: none; */
  /* -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 1px solid darkgray;
  outline: none;
  padding-left: 2px;
  font-weight: bolder;
  box-shadow: 0 0 5px 0px grey inset;
  margin-top: 4px; */

  width: 24px;
  min-width: 24px;
  height: 24px;
  /* margin-bottom: 15px !important; */
}
input[type="checkbox"]:before {
  display: block;
  width: 60%;
}
input[type="checkbox"]:checked:after {
  content: "\2713";
  margin: 0px;
  display: flex;
  background: rgb(230, 68, 39);
    border: 1px solid rgb(230, 68, 39);
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  min-width: 24px;
  color: white;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 24px;
}
@media (min-width: 320px) and (max-width: 1920px) {
  .modalSetting {
    width: 35%;
    /* height:95vh; */
  }
}
@media (min-width: 320px) and (max-width: 1366px) {
  .modalSetting {
    width: 45%;
    /* height:95vh; */
  }
}
@media screen and (min-width: 768px){
  .modalSetting {
    width: 550px;
  }
  
}

    

@media (min-width: 320px) and (max-width: 767px) {
  .modalSetting {
    width: 95%;
    height:95vh;
  }
  .modal-cart-btn {
    margin-left: 10px !important;
    font-size: 16px;
  }
  /* .cart-outer-quantitys {
    width: 50%;
  } */
  .modal-cart-outer-div {
    width: 94%;
  }
  .product-modal-body {
    margin-top: 20px;
    padding: 0px 10px 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .modal-cart-btn {
    /* margin-left: 10px !important; */
    font-size: 16px;
  }
  .modal-cart-outer-div {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 912px) {
  .cart-outer-quantitys {
    width: 100%;
  }
  .modal-cart-outer-div {
    width: 100%;
  }
  .modal-cart-btn {
    /* margin-left: 10px !important; */
    font-size: 16px;
    
  }
  .modalSetting {
    width: 95%;
    height:95vh;
  }
}

@media(min-width:320px) and (max-width:500px)
{
  .shopping-cart-icon{
    display: none !important;
  }
  .modalSettingBanner {
    width: 100%;
  }
  .modal-cart-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: unset !important;
    grid-gap: 8px;
    gap: 8px;
    border: none;
    cursor: pointer;
    height: 40px;
    background: #2f4e49;
    border-radius: 100px;
    flex: none;
    order: 0;
    flex-grow: 1;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    /* margin-left: 25px; */
    color: #1a1a1a;
}
}
.react-responsive-modal-closeButton {
  
  position: absolute;
    top: 14px;
    right: 45px !important;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
}
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}