h4 {
    display: flex;
    flex-direction: row;
  }
  
  .pp:before {
    content: "";
    border-bottom: 1px solid #E6E6E6;
    ;
    /* margin: auto; */
    margin-bottom: 9px;
  }
  
  .pp:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #E6E6E6;
    ;
    /* margin: auto; */
    margin-bottom: 9px;
  }
  .profilepic{
    border-radius: 51px !important;
    border: none !important;
    height: 57px !important;
    /* width: 60px !important; */
    background-color: white;
  }
  .img {
    display: block;
    height: auto;
}
.font-weight-light {
  font-family: Roboto Condensed;
  color: #666666;
  font-weight: 400 !important;
  font-size: 12.8px !important;
  cursor: pointer;
}

.h5{
  font-size: 20px;
  font-weight: 800!important;
}
#roundb {
  background-color: #345C59;
  width: 100%;
  border: none;
  border-radius: 700px;
  height: 5rem;
  cursor: pointer;
}
@media (max-width: 600px) {
  .profile-mobile-div {
    margin-top: 80px !important;
  }
  
}

.signup-btn {
  width: 102%;
  margin-left: -10px !important;
  border-radius: 5px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: #364d49 !important;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 9px;
  text-align: center;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
  color: white;
  font-family: "Playfair Display";
}
.form-footer .btn{
  margin-right: 0px !important;
}