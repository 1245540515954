@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Mooli&family=Noto+Sans:wght@400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@500&family=REM:wght@200;300&display=swap');
nav {
	width: 100%;
	position: sticky;
	z-index: 3;
	top: 0;
	padding: 1.2em 0;
	color: white;
	/* background-color: antiquewhite; */
	max-height: 4em;
}

.font_custom {
	font-family: 'Lato', sans-serif !important;
	font-family: 'Mooli', sans-serif !important;
	font-family: 'Noto Sans', sans-serif !important;
	font-family: 'Open Sans', sans-serif !important;
	font-family: 'Poppins', sans-serif !important;
	font-family: 'REM', sans-serif !important;
}
nav span {
	font-size: 18px;
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease;
	/* transition-delay: 0.25s; */
	margin: 1em;
	/* padding-bottom: 1.3em; */
	position: relative;
	left: -15px;
	top: -35px;

}
.input-group-addon {
	color: black;
    padding: 10px;
    margin: 0px;
    background: rgb(189, 187, 187);
    font-size: 16px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
	top: 0px !important;
	left: 0px !important;
	border-bottom: none !important;
}
.input-group-addon-2 {
	color: black;
    padding: 10px 5px;
    margin: 0px;
	position: absolute;
	left: 95%;
    font-size: 16px;
	top: 0px !important;
	border-bottom: none !important;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
	width: 400px;
	border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

nav span:hover {
	cursor: pointer;
	border-bottom: 1px solid white;
}

nav span:hover i {
	color: gold;
}

nav span.activeClass i {
	color: gold;
}

nav span.activeClass {
	border-bottom-color: var(--lightAccent);
}

.border-class-custom{
	border: 2px solid rgb(255, 255, 255);
}

.btn-group-mod-toggler {
	position: relative;
	bottom: -20px !important;
}


@media only screen and (min-width:766px) and (max-width:991px){
	.fit-container{
		padding: 0px 18px !important;
	}
}

@media (max-width: 600px) {
	.mt-web-view {
		display: none !important;
	}
	nav span {
		top: -15px !important
	}
	.search-column {
		justify-content: center !important;
	}
	.custom-mode-buttons {
		display: flex;
		justify-content: center;
		left: 0px !important;
	}
	.nav-web-search-field {
		display: none !important;
	}
}
