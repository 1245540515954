.slider-main-section {
    background-color: #2f4e49 !important;
    padding-top: 2rem !important;
    padding-bottom: 3rem !important;
  }
  .owl-carousel .owl-item img {
    display: block;
    width: 80% !important;
    margin: auto !important;
    /* border-radius: 37px !important; */
  }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1.2 !important;
    display: inline;
  }
  .owl-theme .owl-dots .owl-dot span:before {
    background-color: #d60000 !important;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    border-color: #d60000;
    background: transparent;
  }
  
  @media only screen and (max-width: 375px) {
    .slider-inner-div {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media only screen and (max-width: 414px) {
    .slider-inner-div {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media only screen and (max-width: 540px) {
    .slider-inner-div {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .upper-slider-button {
    border-color: #2f4e49;
    border-radius: 5px;
    height: 43px;
    display: flex;
    flex-direction: row;
    width: auto;
  
    /* height: ; */
  }
  
  .upper-slider-button1 {
    border-color: #2f4e49;
    border-radius: 6px;
    height: 43px;
    display: flex;
    flex-direction: row;
    width: auto;
  
    /* height: ; */
  }
  .upper-slider-button:hover {
    background-color: #edf8f6 !important;
  }
  .button-content {
    position: relative;
    left: -0px;
    top: -6px;
  }
  .mode-button-body {
    font-family: "GillSansMedium";
    text-transform: capitalize;
  }
  
  .custom-mode-buttons {
    position: relative;
    left: -15px;
  }
  .hero-slider {
    position: relative;
    max-width: 1800px;
    border-radius: 20px;
    height: 400px;
    /* background: #e8e8e8; */
    overflow: hidden;
    border: none;
    box-shadow: none;
  }
  .hero-content{
    margin-top: 62px;
  }
 
  .accept-button-homeSlider {
    display: flex;
    height: 40px;
    padding: 11.142px 35.826px;
    align-items: flex-start;
    gap: 8.142px;
    border-radius: 4.071px;
    background: #364d49;
    color: #FFF;
    text-align: center;
    font-family: Times New Roman;
    font-size: 17.384px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.384px;
    text-transform: capitalize;
    border: none;
    
  }
  .slider-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-blend-mode: multiply;
    object-fit: cover;
  }



  .custom-content{
    margin-top: 8%;
    margin-left: 5%;
  }

  .margin_small_device{
    margin-top: 6%;
    margin-left: 5%;
  }
  
  .slider-item.active {
    opacity: 1;
  }
  .banner_title {
    width: 80%;
    height: 46.849px;
    color: #ffffff;
    font-family: 'GillSansMedium', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.721px;
    text-transform: capitalize;
    margin-bottom: 0px;
  }
  .banner_title_custom {
    width: 90%;
    height: 46.849px;
    color: #ffffff;
    font-family: 'GillSansMedium', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.721px;
    text-transform: capitalize;
    margin-bottom: 60px;
  }
  
  
  
  .banner_description {
    width:60%;
    margin-top: 24px;
    color: #ffffff;
    font-family: QuickSand;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  
    /* line-height: 19.864px; */
  }
  .banner-image {
    width: 179.052px;
    height: 179.052px;
    position: relative;
    left: 20px;
    /* height: 204px; */
    /* top: -15px; */
    /* width: 204px; */
    border-radius: 4.389px;
    /* background: url(<path-to-image>), lightgray 0px 0px / 100% 100% no-repeat; */
  }
  
  .hero-slider-stamp {
    position: relative;
    width: 590px;
    height: 254px;
    left: 0px;
    top: calc(50% - 254px / 2);
    border-radius: 0px;
    overflow: hidden;
    background: #364d49;
  }
  
  .banner_description_stamp {
    width: 265px;
    height: 48px;
    left: 34px;
    top: 111px;
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    /* or 111% */
    display: flex;
    align-items: center;
    letter-spacing: -0.164117px;
    color: #ffffff;
  }
  .slider-item-stamp.active {
    opacity: 1;
    margin-top: 18px;
  }
  .banner-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 25px;
    position: absolute;
    /* height: 37px; */
    left: 10.5px;
    right: 407.5px;
    width: 142px;
    /* top: 174px; */
    background: #ffffff;
    border-radius: 6px;
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    top: 110px;
  }
  .stamp-image {
    /* width: 255.06px; */
    /* height: 256.31px; */
    left: 320px;
    margin-left: 10px;
    border-radius: 10px;
    /* top: calc(50% - 256.31px/2 + 18.16px); */
  }
  .banner-row {
    display: contents;
  }
  .slider-container {
    display: flex;
    position: relative;
    width: 1200px;
    margin: auto;
    border: none;
    box-shadow: none;
  }
  
  .slider-container-b {
    display: flex;
    position: relative;
    width: 1200px;
    margin: auto;
  }
  

  .dots-container{
    width: 20%;
  }

  .order_button{
    display: flex;
    padding: 10px 32px;
justify-content: center;
align-items: center;
border-radius: 8px;
border: none;
background: #FFF;
/* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
font-family: QuickSand;
font-size: 16px;
position: absolute;
font-weight: 800;
top: 70%;
left: 8%;
color: black;
  }

  .mt_cusotm_button{
    top: 76%;
  }

  
  @media only screen and (min-width:766px) and (max-width:991px)
  {
    .banner_title_custom{
      font-size: 32px;
    }
  }
  
  @media (max-width: 593px) {
    .banner-row {
      display: flex;
    }
    
  
    .dot {
      background-color: rgb(185, 185, 185) !important;
    }
    .dot.active {
      background-color: #E64427 !important;
    }
    .custom-banner-mobile {
      margin-left: 0px !important;
      padding-left: 0px !important;
    }
    .slider-container {
      display: block;
      padding: 0px 0px !important;
      /* margin-top: 25% !important; */
      margin: 0%;
     
    }
  
    .slider-container-b{
      display: block;
    }
  
  
    .web-mobile-app-install {
      display: none;
    }
    .banner-button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 35px;
      position: absolute;
      /* height: 37px; */
      left: 8.5px;
      right: 407.5px;
      /* width: 194px; */
      width: 141px;
      top: 105px;
      background: #ffffff;
      border-radius: 6px;
      font-family: "Times New Roman";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-transform: capitalize;
    }
    .banner_title {
      font-size: 30px;
    }
    .banner-image {
      height: 190px !important;
      width: 190px !important;
      margin-right: 10px !important;
      /* left: 0px !important;
      top: -10px !important;*/
    } 
    .banner_description {
      /* width: 160px;
      text-align: left; */
    }
    .banner_description_stamp {
      width: 190px;
      text-align: left;
      height: 48px;
      left: 34px;
      top: 111px;
      font-family: "Times New Roman";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.164117px;
      color: #ffffff;
    }
    .hero-slider-stamp {
      position: relative;
      width: 100%;
      height: 200px;
      left: 0px;
      top: 0px;
      border-radius: 10.1508px;
      overflow: hidden;
      background: #E64427;
    }
    .hero-slider {
      position: relative;
      width: 100%;
      height: 185px;
      left: 0px;
      background: #e8e8e8;
  
      overflow: hidden;
      top: -8px;
    }
    .container {
      left: 0px !important;
    }
    .top-left {
      position: absolute;
      top: 8px;
      left: 35px;
      font-size: large;
    }
    .mode-slider-button1 {
      border-color: #2f4e49;
      border-radius: 5px 0px 0px 5px;
      height: 43px;
      display: flex;
      flex-direction: row;
      width: 180px;
    }
    .mode-slider-button2 {
      border-color: #2f4e49;
      border-radius: 0px 5px 5px 0px;
      height: 43px;
      display: flex;
      flex-direction: row;
      width: 190px;
    }
    .dinein-body {
      margin-left: 35px;
    }
    .cart-sticky {
      display: none;
    }
    body {
      /* overflow: hidden !important; */
    }
    .banner-row {
      margin: 0px !important;
    }
    .custom-mode-buttons {
      left: 10px;
    }
    /* .dots-container {
      left: 35% !important;
      top: 0px !important;
    } */
  }
  
  
  @media only screen and (min-width:530px) and (max-width:590px)
  {
    .slider-container-b{
      margin-top: 25% !important;
    }
  
  
  
  }
  
  @media (min-width: 993px) and (max-width: 1200px) {
  
    .hero-slider-stamp {
      width: 450px;
    }
    .banner_description_stamp {
      width: auto;
    }

    .banner_description {
      width: 80%;
    } 

    .order_button{
left: 11%;
    }

    .slider-container{
      width: 960px;
     
    }

    .slider-container-b{
      width: 960px;
    }

    .margin_small_device{
      margin-top: 8%;
    }

    .custom-content{
      margin-top: 8%;
    }

    .banner_title {
      font-size: 40px;
      width: 100%;
    }
    .banner_title_custom{
        font-size: 40px;
    }
    .banner-image {
      left: 0px;
    }

    .dots-container {
      left: 42% !important;
    }
  
  }

  @media only screen and (min-width:901px) and (max-width:950px)
  {
    .slider-container{
width: 850px;
margin-top: 5.5rem !important;
    }

    .banner_title_custom{
      font-size: 38px;
    }

    .banner_title{
      font-size: 38px;
      width: 100%;
    }

    .banner_description{
      width: 90%;
      margin-top: 1rem;
    }

    .slider-container-b{
      width: 850px;
      margin-top: 8.5rem !important;
    }

    .margin_small_device{
      margin-top: 12%;
    }

    .custom-content{
      margin-top:12%;
    }

    .order_button{
left: 12% !important;
    }
  }

  @media (min-width: 601px) and (max-width: 900px) {
    .mobile-header {
      display: none;
    }
    .mobile-app-install-banner {
      display: none;
    }
    .banner-row {
      display: block;
    }
    .hero-slider {
      width: 100%;
      left: 0px !important;
      right: 0px;
      height: 300px;
    }
    .hero-slider-stamp {
      width: auto;
    }
    .slider-container {
      position: initial;
      display: block;
      width: 700px;
    }
    .custom-content{
        margin-top: 10%;
        margin-left: 5%;
    }

    .margin_small_device{
        margin-top: 4%;
        margin-left: 5%;
      }

    

    
  .banner_description{
    width: 100%;
  }
    .slider-container-b{
      width: 700px;
    }
    .banner_title{
      width: 90%;
      font-size: 32px !important;
      margin-bottom: -10px;

    }
  .banner_title_custom {
    width: 86%;
    margin-bottom: 30px;
  }
    
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background: #E64427;
  }
  
  .dot.unactive{
    background-color: #d7dbda;
  }
  
  .slider-navigation {
    /* position: relative; */
    z-index: 2;
    cursor: pointer;
    /* z-index: 1; */
  }
 
  .fa-angle-right {
    font-size: 20px;
    width: 130%;
  }
  .fa-angle-left {
    font-size: 20px;
    width: 130%;
  }
  .card-custom-banner {
    /* padding: 15px; */
    padding: 1rem;
    /* border: 2px solid #f2f2f2; */
    border-radius: 5px;
    background: #fff;
    position: relative;
    z-index: 1;
    cursor: pointer;
    /* width: 450px; */
    height: 124px;
  }
  /* ... */
  
  
  .icon_class{
      width: 40px;
      height: 95px !important;
      background-color:rgba(46, 44, 44, 0.40);
      color: #ffffff;
      /* border-radius: 50%; */
      margin: 0px 10px;
  }
  
  
  
  @media only screen and (max-width:1200px) and (min-width:991px)
  {
    .slide-left{
  left: -4% !important;
    }

    .slide-right{
        right: -1% !important;
          }

          .custom-content{
            margin-top: 13%;
          }
  
  }
  
  @media only screen and (max-width:840px) and (min-width:700px)
  {

    .order_button{
        left: 13%;
      }

    .slide-left{
  left: -4.5% !important;
  top: 33% !important;
    }
.slide-right{
    right: -1% !important;
    top: 33% !important;
}

  
  }
  
  @media only screen and (min-width:500px) and (max-width:600px)
  {
    .slide-left{
        left: 18% !important;
        display: none !important;
          }
          .slide-right{
        right: 15% !important;
        display: none !important;
          }

          .margin_small_device{
            margin-top: 5%;
          }
      
          .custom-content{
              margin-top: 7% !important;
          }
      
          
        
          .banner_title{
            margin-bottom: -35px !important;
            font-size: 18px !important;
            width: 100% !important;
            margin-left: 0px !important;
            white-space: nowrap;
            padding-left: 16px !important;
          }
        
          .banner_title_custom {
           margin-bottom: -10px !important;
            font-size: 18px !important;
            width: 80% !important;
            margin-left: 0px !important;
            padding-left: 16px !important;
        
        }
        
          .banner_description{
            padding-bottom: 0% !important;
             margin-left: 0px !important;
             width: 100%;
             font-size: 12px !important;
             padding-left: 16px !important;
          }
        
          .dots-container{
            top: 112% !important;
            width: 26% !important;
            left: 35.5% !important;
          }
      
          .slider-container{
              margin-top: 8rem !important;
              width: 100%;
          }

          .dots-container{
          
        }

        .order_button{
            left: 9%;
            padding: 8px 24px;
            font-size: 14px;
            top: 100%;
          }
  }
  
  @media only screen  and  (min-width:380px) and (max-width:500px)   {



    .custom-content{
        margin-top: 10% !important;
        margin-left: -2%;
    }

    .margin_small_device{
        margin-top: 6% !important;
        margin-left: -2%;
    }

  
  
    .banner_title{
      margin-bottom: -32px !important;
      font-size: 24px !important;
      width: 100% !important;
      margin-left: 0px !important;
      white-space: nowrap;
      padding-left: 16px !important;
    }
  
    .banner_title_custom {
     margin-bottom: -5px !important;
      font-size: 24px !important;
      width: 100% !important;
      margin-left: 0px !important;
      padding-left: 16px !important;
  
  }
  
    .banner_description{
      padding-bottom: 0% !important;
       margin-left: 0px !important;
       width: 100%;
       font-size: 16px !important;
       padding-left: 16px !important;
    }
  
    .dots-container{
      top: 82% !important;
      width: 26% !important;
      left: 37.5% !important;
    }

    .slider-container{
        margin-top: 9.7rem !important;
        width: 100%;
        margin-bottom: -8.5rem !important;
    }

    .slider-container-b{
      margin-top: 13.0rem !important;
      width: 100%;
      margin-bottom: -8.5rem !important;
    }


    .slide-left{
      display: none !important;
    }

    .slide-right{
      display: none !important;
    }

    .hero-slider{
      border-radius: 0px;
      height: 240px !important;
    }

    .order_button{
        left: 9%;
        padding: 6px 15px;
        font-size: 14px;
        top: 100%;
        letter-spacing: 0.3px;
        top: 110%;
        color: black;
      }
      .mt_cusotm_button{
        top: 100%;
      }
  }
  
  
  @media only screen and (min-width:320px) and (max-width:378px)
  {
    .custom-content{
      margin-top: 10% !important;
      margin-left: -2%;
  }

  .margin_small_device{
      margin-top: 6% !important;
      margin-left: -2%;
  }



  .banner_title{
    margin-bottom: -32px !important;
    font-size: 24px !important;
    width: 100% !important;
    margin-left: 0px !important;
    white-space: nowrap;
    padding-left: 16px !important;
  }

  .banner_title_custom {
   margin-bottom: -5px !important;
    font-size: 24px !important;
    width: 100% !important;
    margin-left: 0px !important;
    padding-left: 16px !important;

}

  .banner_description{
    padding-bottom: 0% !important;
     margin-left: 0px !important;
     width: 100%;
     font-size: 16px !important;
     padding-left: 16px !important;
  }

  .dots-container{
    top: 85% !important;
    width: 26% !important;
    left: 37.5% !important;
  }

  .slider-container{
      margin-top: 9.5rem !important;
      width: 100%;
      margin-bottom: -8.5rem !important;
  }

  .slider-container-b{
    margin-top: 15.0rem !important;
    width: 100%;
    margin-bottom: -8.5rem !important;
  }

  .slide-left{
    display: none !important;
  }

  .slide-right{
    display: none !important;
  }

  .hero-slider{
    border-radius: 0px;
    height: 220px !important;
  }

  .order_button{
      left: 9%;
      padding: 6px 15px;
      font-size: 14px;
      top: 100%;
      letter-spacing: 0.3px;
    }
    .mt_cusotm_button{
      top: 100%;
    }
  }
  
  
  @media only screen and (max-width:300px)
  {

    .dots-container{
        display: none !important;
    }
    .slide-left{
      left: 10% !important;
      display: none !important;
        }
        .slide-right{
      right: 1% !important;
      display: none !important;
        }
      
        .banner_title{
          margin-bottom: -35px !important;
          font-size: 16px !important;
          width: 100% !important;
          margin-left: 0px !important;
          white-space: nowrap;
          padding-left: 16px !important;
        }
      
        .banner_title_custom {
         margin-bottom: 5px !important;
          font-size: 16px !important;
          width: 90% !important;
          margin-left: 0px !important;
          padding-left: 16px !important;
      
      }
      
        .banner_description{
          padding-bottom: 0% !important;
           margin-left: 0px !important;
           width: 100%;
           font-size: 10px !important;
           padding-left: 16px !important;
        }
      
        .dots-container{
          top: 111.8% !important;
          width: 34%;
          left: 34.5% !important;
        }
  
        .banner-image{
          height: 150px;
          width: 200px;
          object-fit: cover !important;
        }
  
        .slider-container-b{
          margin-top: 46% !important;
        }
        .hero-slider{
          height: 145px;
        }
        .banner_description{
          padding-bottom: 38% !important;
        }
  }