@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Mooli&family=Noto+Sans:wght@400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@500&family=REM:wght@200;300&display=swap');
.featured-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  padding-top: 4rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #2d5c48;
  font-family: "Playfair Display";
}

.featured-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
}
.card-custom {
  /* padding: 15px; */
  padding: 1rem;
  /* border: 2px solid #f2f2f2; */
  border-radius: 5px;
  background: #fff;
  position: relative;
  z-index: 1;
  cursor: pointer;
  /* width: 450px; */
  height: 150px;
  overflow: hidden;
}
.featured-products-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.featured-products-static {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #f7f7f7;
}

.card-container {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  width: 343px;
  height: auto;
  padding: 2rem;
  border: 2px solid #f2f2f2;
  border-radius: 16px;
  justify-content: space-between;
}
.card-product-name {
  font-style: normal;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: auto;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  color: #212524;
}
.card-product-price {
  font-family: 'Quicksand', sans-serif;
  /* color: #4d4d4d; */
  font-size: 16px;
  /* font-style: normal; */
  /* font-weight: 700; */
  line-height: 17.92px; /* 140% */
  font-style: normal;
  font-weight: 700;
  /* font-size: 16px; */
  /* line-height: 24px; */
  align-items: center;
  color: #212524;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;

}
.card-product-desc {
  color: #666;

  font-size: 12.8px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.92px; /* 140% */
font-family: 'Quicksand', sans-serif;
  align-items: center;
  margin-bottom: 5px;
  min-height: 36px;
  max-height: 36px;
  overflow: hidden;
  margin-bottom: 0px;
}

/* .card-btn{
         background: #FFD400;
         border: none;
         font-size: 12.;
      
         font-style: normal;
         font-weight: 700;
         font-size: 12.8px;
         line-height: 20px;
      
         border-radius: 100px;
         height: 32px;
         width: 113px;
  } */
.card-fav {
  font-size: 26px;
}
.outer-heart {
  width: 42px;
  background: #f7f7f7;
  border-radius: 23px;
  /* margin: auto; */
  padding: -16px;
  display: flex;
  justify-content: center;
  padding: 8px;
}
/* .card-fav svg{
      font-size: 32px;
      color: black;
      padding: -15px;
      background: #F7F7F7;
      height: 22px;
      border-radius: 100px;
      margin-top: 6px;
  } */
.product-img {
  width: 100% !important;

  margin: auto;
}
.card-container:hover {
  /* box-shadow: -2px 5px 13px 0px rgba(214,0,0,0.59);
      -webkit-box-shadow: -2px 5px 13px 0px rgba(214,0,0,0.59);
      -moz-box-shadow: -2px 5px 13px 0px rgba(214,0,0,0.59); */
  cursor: pointer;
}
.featured-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  padding-top: 4rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #2d5c48;
  font-family: "Playfair Display";
}
.outer-main-menu {
  background-color: #f7f7f7;
}
.outer-card {
  display: flex;
}
.combo-detail-heading {
  margin-left: 10px;
}
.combo-detail-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2d5c48;
  margin-bottom: 9px;
  /* padding-top: 1rem; */
}

#fixedbutton {
  font-size: 24px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #364d49;
  border-radius: 50px;
  z-index: 1;
}
.p_sticky_view_cart {
  display: none;
  cursor: pointer;
  width: 100%;
  padding-left: 0.5rem;
    padding-right: 1.5rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  
}

.cart_bar{
  background-color: #171818;
}

.p_sticky_view_cart_btn {
  padding: 0 20px;
  height: 45px;
  background-color: #171818;
  color: #fff !important;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  align-items: center;
}
.view_basket_icon {
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 20px;
  line-height: 1.25rem;
}
.custom-default-green-button {
  background-color: black !important;
  right: -8px;
}
.badge-circle {
  position: absolute;
  top: -3px;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
.cart-hover {
  animation: shake 0.9s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@media only screen and (min-width: 320px) and (max-width: 559px) {

  .card-product-desc{
    width: 78%;
  }

  #fixedbutton {
    display: none;
  }


  .p_sticky_view_cart {
    display: block;
    position: fixed !important;
    
    z-index: 999 !important;
  }
}
.timeline-item {
  padding: 2rem;
  /* border: 2px solid #f2f2f2; */
  /* border-radius: 16px; */
  /* background: #fff; */
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  min-height: 200px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 154px;
  position: relative;
  border-radius: 16px;
}

.background-masker {
  background: #fff;
  position: absolute;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  /* left: 40px; */
  right: 0;
  height: -8px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: -1px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: -4px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 0px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
  height: -13px;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 0px;
}

.background-masker.content-top {
  height: 0px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  /* left: 300px; */
  top: 88px;
}


  

@media only screen and (min-width:400px) and (max-width:576px){

    .cart_bar{
    background-color: rgb(17, 17, 17);
    z-index: 99999;
    position: fixed;
    bottom: 2%;
    width: 95%;
padding: 15px 15px;
margin: 0px 6px;
color: white;
border-radius: 10px;
transition: opacity 0.3s ease;
  }
 
  .cart_bar_scroll{
    background-color: rgb(17, 17, 17);
    z-index: 99999;
    position: fixed;
    bottom: 36%;
    width: 95%;
padding: 15px 15px;
margin: 0px 6px;
color: white;
border-radius: 10px;
transition: opacity 0.3s ease;
  }
}



@media only screen and (min-width:377px) and (max-width:395px)
{
    .cart_bar{
    background-color: rgb(17, 17, 17);
    z-index: 99999;
    position: fixed;
    bottom: 2%;
    width: 95%;
padding: 15px 15px;
margin: 0px 6px;
color: white;
border-radius: 10px;
transition: opacity 0.3s ease;
  }
  
  .cart_bar_scroll{
    background-color: rgb(17, 17, 17);
    z-index: 99999;
    position: fixed;
    bottom: 39%;
    width: 95%;
padding: 15px 15px;
margin: 0px 6px;
color: white;
border-radius: 10px;
transition: opacity 0.3s ease;
  }

 

}

@media only screen and (max-width:376px)
{
  
  .cart_bar{
    background-color: rgb(17, 17, 17);
    z-index: 99999;
    position: fixed;
    bottom: 2%;
    width: 95%;
padding: 15px 15px;
margin: 0px 6px;
color: white;
border-radius: 10px;
transition: opacity 0.3s ease;
  }

  .cart_bar_scroll{
    background-color: rgb(17, 17, 17);
    z-index: 99999;
    position: fixed;
    bottom: 43%;
    width: 95%;
padding: 15px 15px;
margin: 0px 6px;
color: white;
border-radius: 10px;
transition: opacity 0.3s ease;
  }

 

}